.invalid-field {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
}

.border-input-default {
  border-color: #f0f2f4 !important;
}

.login--logo-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 30px;
}

.login--logo-container img {
  height: 100%;
  width: auto;
}

.sidebar-logo {
  height: 35px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background-color: transparent;
}

.sidebar-logo img {
  height: 100%;
  width: auto;
}

.mobile-menu-button {
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 24px;
  width: 24px;
  background-color: transparent;
}

.mobile-menu-button img {
  height: 100%;
  width: auto;
}

.close-mobile-menu-button {
  right: 0;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 44px;
  width: 44px;
  background-color: transparent;
}

.mobile-menu {
  top: 0;
  height: 100%;
}

.header-dashboard--logo-container {
  height: 30px;
}

.header-dashboard--logo-container img {
  height: 100%;
  width: auto;
}

.label-status-notification {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  color: #ffffff;
  background-color: #dc3545;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  margin-left: 5px;
  font-weight: 500;
  line-height: 0;
}

.nav-link.new-project {
  min-width: 134px;
  display: flex;
  align-items: center;
}

.bg-dark-grey {
  background-color: #5a5b5d;
}

.agreement-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 10px 0 0;
}

.agreement-img {
  width: 100%;
  height: auto;
  transition: transform 0.5s ease-in-out;
}

.agreement-nav-group {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.agreement-nav-item {
  height: 20px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  background-color: #5a5b5d;
  color: #ffffff;
  border: none;
  margin: 0 3px;
}

/* begin:: ask permission modal */

.ask-permission-modal__container {
  width: 300px;
  padding: 10px;
  border-radius: 10px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.ask-permission-modal__title {
  font-weight: 700;
}

.ask-permission-modal__text-content {
  font-size: 14px;
  text-align: center;
}

.ask-permission-modal__btn {
  height: 38px;
  width: 100%;
  border-radius: 5px;
  box-shadow: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  transition: all ease-in-out 0.1s;
}

.ask-permission-modal__btn.allow {
  background-color: #555555;
  border: 1px solid #555555;
  color: #ffffff;
}

.ask-permission-modal__btn.not-allow {
  background-color: #ffffff;
  border: 1px solid #ffffff;
  color: #555555;
  margin: 10px 0 0 0;
}

.ask-permission-modal__btn.allow:hover {
  background-color: #ffffff;
  border: 1px solid #ffffff;
  color: #555555;
}

.ask-permission-modal__btn.not-allow:hover {
  background-color: #555555;
  border: 1px solid #555555;
  color: #ffffff;
}

/* end:: ask permission modal */

.design-plan__picture {
  width: 212.58px;
  height: 212.58px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.design-plan__picture img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.design-plan__info-1 {
  width: 152.73px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.design-plan-detail__label {
  width: 150px;
  font-size: 14px !important;
  font-family: 'Rubik', 'Poppins', serif !important;
}

.design-plan-detail__info {
  font-size: 14px !important;
  font-weight: 700 !important;
  word-break: break-all;
  width: calc(100% - 150px);
}
