.attachment-preview__container {
  width: 100%;
  height: 114px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1rem;
  border-radius: 4.8px;
  border: 1px solid #f0f2f4;
}

.attachment-preview__thumbnail {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  border-radius: 4.8px;
  padding: 0 !important;
  overflow: hidden;
}

.attachment-preview__thumbnail img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.attachment-preview__info {
  width: calc(100% - 80px - 1rem - 1rem);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 1rem;
}

.attachment-preview__title {
  color: #333333;
  margin-bottom: 0.5rem;
}

.attachment-preview__size {
  color: #6c757d;
  font-size: 0.875rem;
}

.attachment-preview__clear-attachment {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 26px;
  cursor: pointer;
  color: #f0f2f4;
  font-size: 1.6rem;
  margin-left: auto;
  background-color: transparent;
  border: none;
}

.attachment-preview__clear-attachment img {
  width: 16px;
  height: auto;
  object-fit: cover;
}
