.easy-crop-profile-picture__container {
  position: relative;
  width: 100%;
  height: 200px;
}

.easy-crop-profile-picture__container img {
  height: auto;
  width: auto;
}

/* .easy-crop-profile-picture__container .reactEasyCrop_CropArea {
  width: 150px !important;
  height: 150px !important;
} */

.controls {
  margin-top: 20px;
  width: 100%;
  /* position: absolute;
  bottom: 20px;
  left: 50%;
  width: 50%;
  transform: translateX(-50%);
  height: 40px;
  display: flex;
  align-items: center; */
}

/* .slider {
  padding: 22px 0px;
} */

/* .zoom-range {
  -webkit-appearance: none;
  -moz-appearance: none;
  height: 2px;
  border-top: 5px solid #fff;
  border-bottom: 5px solid #fff;
  background: #3f51b5;
  width: 100%;
}

.zoom-range::-moz-range-thumb {
  -webkit-appearance: none;
  -moz-appearance: none;
  border: 1px solid #3f51b5;
  background: #3f51b5;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.zoom-range::-webkit-slider-thumb {
  -webkit-appearance: none;
  -moz-appearance: none;
  border: 1px solid #3f51b5;
  background: #3f51b5;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.controls:hover input[type='range']::-webkit-slider-thumb {
  box-shadow: 0px 0px 0px 8px rgba(63, 81, 181, 0.16);
  border-radius: 50%;
}

.controls:hover input[type='range']::-moz-range-thumb {
  box-shadow: 0px 0px 0px 8px rgba(63, 81, 181, 0.16);
} */

.zoom-range-profile-picture {
  -webkit-appearance: none;
  width: 100%;
  height: 10px;
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
  border-radius: 50px;
}

.zoom-range-profile-picture:hover {
  opacity: 1;
}

.zoom-range-profile-picture::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 15px;
  height: 15px;
  background: #6c757d;
  cursor: pointer;
  border-radius: 50%;
}

.zoom-range-profile-picture::-moz-range-thumb {
  width: 15px;
  height: 15px;
  background: #6c757d;
  cursor: pointer;
  border-radius: 50%;
}

.rotation-range-profile-picture {
  -webkit-appearance: none;
  width: 100%;
  height: 10px;
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
  border-radius: 15px;
}

.rotation-range-profile-picture:hover {
  opacity: 1;
}

.rotation-range-profile-picture::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 15px;
  height: 15px;
  background: #6c757d;
  cursor: pointer;
  border-radius: 50%;
}

.rotation-range-profile-picture::-moz-range-thumb {
  width: 15px;
  height: 15px;
  background: #6c757d;
  cursor: pointer;
  border-radius: 50%;
}

.easy-crop-profile-picture__button-container {
  margin: 20px 0 0 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.easy-crop-profile-picture__button-container > :not(:last-child) {
  margin-right: 0.25rem;
}

.easy-crop-profile-picture__button {
  height: 38px;
  padding: 6px 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
}

.easy-crop-profile-picture__button.save {
  border: 1px solid #6c757d;
  background-color: #6c757d;
  color: white;
}

.easy-crop-profile-picture__button.cancel {
  border: 1px solid #6c757d;
  background-color: #ffffff;
  color: #6c757d;
}

.easy-crop-cover-profile-picture__container {
  position: relative;
  /* width: 80vw;
  height: 318px; */
  /* margin: 0 30px; */
}

.easy-crop-cover-profile-picture__container
  .reactEasyCrop_Cover_Horizontal
  img {
  width: 100%;
  height: auto;
}

.zoom-range-cover-profile-picture {
  -webkit-appearance: none;
  width: 100%;
  height: 10px;
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
  border-radius: 50px;
}

.zoom-range-cover-profile-picture:hover {
  opacity: 1;
}

.zoom-range-cover-profile-picture::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 15px;
  height: 15px;
  background: #6c757d;
  cursor: pointer;
  border-radius: 50%;
}

.zoom-range-cover-profile-picture::-moz-range-thumb {
  width: 15px;
  height: 15px;
  background: #6c757d;
  cursor: pointer;
  border-radius: 50%;
}

.rotation-range-cover-profile-picture {
  -webkit-appearance: none;
  width: 100%;
  height: 10px;
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
  border-radius: 15px;
}

.rotation-range-cover-profile-picture:hover {
  opacity: 1;
}

.rotation-range-cover-profile-picture::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 15px;
  height: 15px;
  background: #6c757d;
  cursor: pointer;
  border-radius: 50%;
}

.rotation-range-cover-profile-picture::-moz-range-thumb {
  width: 15px;
  height: 15px;
  background: #6c757d;
  cursor: pointer;
  border-radius: 50%;
}

.easy-crop-cover-profile-picture__button-container {
  margin: 20px 0 0 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.easy-crop-cover-profile-picture__button-container > :not(:last-child) {
  margin-right: 0.25rem;
}

.easy-crop-cover-profile-picture__button {
  height: 38px;
  padding: 6px 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
}

.easy-crop-cover-profile-picture__button.save {
  border: 1px solid #6c757d;
  background-color: #6c757d;
  color: white;
}

.easy-crop-cover-profile-picture__button.cancel {
  border: 1px solid #6c757d;
  background-color: #ffffff;
  color: #6c757d;
}
